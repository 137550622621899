/* eslint-disable prefer-object-spread */
import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../../data/axiosInstance/axiosInstance';
import executor from '../../../data/axiosInstance/executor';
import { EnabledDisabledStateTrueFalsePresaCarico } from '../../customizer/CustomizerSlice';
import { removeTaskToNav } from '../addNavTask/AddNavTaskSlice';
import { checkTotalItemMessageToast, setTotalItemMessageToast } from '../ws/WsSlice';
import profileValidation from '../../../components/jwt/profile.validation';
import EnumService from '../../../components/jwt/enum.service';
import dateUtils from '../../../components/jwt/dateUtils.service';

const API_URL = `${process.env.REACT_APP_API_SINISTRI_URL}`;

const initialState = {
  ticketsLavorabili: [],//Tutti i ticket visualizzati nella gridLavorabili
  ticketsAssegnate: [],//Tutti i ticket visualizzati nella gridAssegnate
  currentFilter: 'total_tickets',//To do gestione filtri grid
  ticketSearch: '',//To do Gestione Filtri
  openedTicket: {},//
  //setAdminData: {},
  allOpenedTicket: [],
  totalOpenedTicket: 0,
  taskId: '',
  loading: false,loadingGrid: false,
  isSlowFormVisible:  false,
  taskIdInCharge: '',
  userData: {},
  calcoloMassimaliExtOpnenedticket: {},//Per Modale garanzie Massimali 
  recuperoFranchigia: [],
  totaleRecuperoFranchigia: 0,
  listaBeneficiariRecuperoFranchigia: [],
  isButtonEnabledMassimaleGaranzieVisible : true,
  listaBeneficiariRecuperoFranchigiaTable: [],
  language: {},
  sla: {},
  errorSla: ""
};

export const TicketSlice = createSlice({
  name: 'ticket', 
  initialState,
  reducers: {
    getTicketsLavorabili: (state, action) => {
      //state.ticketsLavorabili = {};
      if(action.payload)
        state.ticketsLavorabili = action.payload;
    },
    setErrorSla: (state, action ) => {
      state.errorSla = action.payload
    },
    getTicketsAssegnate: (state, action) => {
      // state.ticketsAssegnate = {};
      if(action.payload)
        state.ticketsAssegnate = action.payload;
    },
    getTicket: (state, action) => {
      state.openedTicket = {};
      state.openedTicket = { ...{}, ...action.payload };
    },
    getTicketTree: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index=state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask === action.payload.datiTask.idTask);
      if (index !== -1) {
        state.allOpenedTicket[index].datiTaskFigli =action.payload?.datiTaskFigli;
      }
    }, 
    setVisibilityFilter: (state, action) => {//NOTUSED
      state.currentFilter = action.payload;
    },
    SearchTicket: (state, action) => {//NOTUSED
      state.ticketSearch = action.payload;
    },
    DeleteTicket: (state, action) => {//NOTUSED
      const index = state.tickets.findIndex((ticket) => ticket.Id === action.payload);
      state.tickets.splice(index, 1);
    },
    setOpenedTicketClaimData: (state, action) => {//
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      //dopo aver reperito il ticket dallo store, lo popoliamo con i dati denuncia
      // const tckObject = Object.assign({}, state.openedTicket?.datiDenuncia);
      // state.allOpenedTicket[index].datiDenuncia = { ...tckObject };
      state.openedTicket.datiDenuncia = { ...action.payload };
      state.allOpenedTicket[index].datiDenuncia = { ...action.payload };
      //this is shit we have to refactor denuncia
      state.allOpenedTicket[index].datiDenuncia.titoloAmministrativoId = state.openedTicket?.datiDenuncia.titoloAmministrativoId;
    },
    setOpenedTicketDatiCausa: (state, action) => {//
      //state.openedTicket.datiDenuncia = { ...state.openedTicket.datiDenuncia, ...action.payload}; old

      //cerca tra i ticket aperti quello uguale a quello attualmente visualizzato e aggiornalo
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      //dopo aver reperito il ticket dallo store, lo popoliamo con i dati denuncia
      const tckObject = Object.assign({}, state.openedTicket?.datiCausa);
      state.allOpenedTicket[index].datiCausa = { ...tckObject };
      state.allOpenedTicket[index].datiCausa = { ...action.payload };
    },
    setOpenedTicketDatiIncarico: (state, action) => {//
      //state.openedTicket.datiDenuncia = { ...state.openedTicket.datiDenuncia, ...action.payload}; old

      //cerca tra i ticket aperti quello uguale a quello attualmente visualizzato e aggiornalo
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      //dopo aver reperito il ticket dallo store, lo popoliamo con i dati denuncia
      const tckObject = Object.assign({}, state.openedTicket?.datiIncarico);
      state.allOpenedTicket[index].datiIncarico = { ...tckObject };
      state.allOpenedTicket[index].datiIncarico = { ...action.payload };
    },
    setOpenedTicketAdminData: (state, action) => {//
      //state.setAdminData = {};
      //state.openedTicket.datiDenuncia.titoloAmministrativoId = action.payload;  //old

      //cerca tra i ticket aperti quello uguale a quello attualmente visualizzato e aggiornalo
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.datiDenuncia.titoloAmministrativoId = action.payload;
      //dopo aver reperito il ticket dallo store, lo popoliamo con i dati denuncia
      const tckObject = Object.assign({}, state.openedTicket?.datiDenuncia);
      state.allOpenedTicket[index].datiDenuncia = { ...tckObject };
      state.allOpenedTicket[index].datiDenuncia.titoloAmministrativoId = action.payload;
    },
    setOpenedTicketAdminDataRender: (state, action) => {//
      //state.setAdminData = {};
      //state.openedTicket.datiDenuncia.titoloAmministrativoId = action.payload;  //old

      //cerca tra i ticket aperti quello uguale a quello attualmente visualizzato e aggiornalo
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      //dopo aver reperito il ticket dallo store, lo popoliamo con i dati denuncia
      // const tckObject = Object.assign({}, state.openedTicket?.adminDataRender);
      // state.allOpenedTicket[index].adminDataRender = { ...tckObject };
      state.openedTicket.adminDataRender = action.payload;
      state.allOpenedTicket[index].adminDataRender = action.payload;
    },
    setOpenedTicketNPolizza: (state, action) => {//
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      //const tckObject = Object.assign({}, state.openedTicket?.datiTask?.nPolizza);
      //state.allOpenedTicket[index].datiTask.nPolizza = { ...tckObject };
      state.openedTicket.datiTask.nPolizza = action.payload;
      state.allOpenedTicket[index].datiTask.nPolizza = action.payload;
    },
    clearDatiDenuncia: (state, action)=> {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      //const tckObject = Object.assign({}, state.openedTicket?.datiTask?.nPolizza);
      //state.allOpenedTicket[index].datiTask.nPolizza = { ...tckObject };
      state.openedTicket.datiDenuncia.numeroPolizza = action.payload;
      state.allOpenedTicket[index].datiDenuncia.numeroPolizza = action.payload;
      state.openedTicket.datiDenuncia.titoloAmministrativoId = action.payload;
      state.allOpenedTicket[index].datiDenuncia.titoloAmministrativoId = action.payload;
      state.openedTicket.datiDenuncia.idBene = action.payload;
      state.allOpenedTicket[index].datiDenuncia.idBene = action.payload;
      state.openedTicket.datiDenuncia.motivazione = action.payload;
      state.allOpenedTicket[index].datiDenuncia.motivazione = action.payload;
    },
    setDenunciaMotivazione: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.allOpenedTicket[index].datiDenuncia.motivazione = action.payload;
      state.openedTicket.datiDenuncia.motivazione = action.payload; //this is shit, we will correct on alert
    },
    setTipoIncaricato: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.tipoIncaricato);
      state.allOpenedTicket[index].tipoIncaricato = { ...tckObject };
      state.allOpenedTicket[index].tipoIncaricato = action.payload;
      state.allOpenedTicket[index].datiIncarico.tipoIncarico = action.payload;
    },

    setIdTicketInCharge: (state, action) => {
      if (!state.taskIdInCharge.includes(action.payload)) {  //se il ticket non è già in carico aggiungilo alla lista
        const oldTaskIdInCharge = state.taskIdInCharge;
        state.taskIdInCharge = '';
        state.taskIdInCharge = oldTaskIdInCharge.concat(action.payload, ";");
      }
    },
    removeIdTicketInCharge: (state, action) => {
      const oldTaskIdInCharge = state.taskIdInCharge;
      state.taskIdInCharge = '';
      state.taskIdInCharge = oldTaskIdInCharge.replace(action.payload.toString().concat(";"), "");
    },
    addOpenedTicket: (state, action) => {
      // eslint-disable-next-line eqeqeq
      if (state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask === action.payload.datiTask.idTask) === -1) {
        state.allOpenedTicket = [...state.allOpenedTicket, action.payload];
        state.totalOpenedTicket += 1;
      }
      state.taskId = {};
      state.taskId = action.payload.datiTask.idTask;  //aka getIdTicket
      state.openedTicket = {};
      state.openedTicket = Object.assign({}, action.payload);
      localStorage.setItem('idFlusso', action.payload?.datiTask?.idFlusso);
    },
    setOpenedTicketChangePolizza: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index=state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask === action.payload.datiTask.idTask);
      if (index !== -1) {
        state.allOpenedTicket.splice(index,1);
        state.allOpenedTicket = [...state.allOpenedTicket, action.payload];
      }
    },
    addTaskToNavSinstro: (state, action) => { 
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.allOpenedTicket[index].navTaskListSinistro =state.allOpenedTicket[index].navTaskListSinistro !== undefined?  [...state.allOpenedTicket[index].navTaskListSinistro] : [];
      state.openedTicket.navTaskListSinistro =state.openedTicket.navTaskListSinistro !== undefined ? [...state.openedTicket.navTaskListSinistro] : [];
      if(state.openedTicket?.navTaskListSinistro){
        state.allOpenedTicket[index].navTaskListSinistro = [ ...state.allOpenedTicket[index].navTaskListSinistro, action.payload ];
        state.openedTicket.navTaskListSinistro = [ ...state.openedTicket.navTaskListSinistro, action.payload ];
      }
    },
    addOpenedTicketCached: (state, action) => {
     // eslint-disable-next-line eqeqeq
      const tckObject =  {...state.allOpenedTicket[(state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == action.payload.datiTask.idTask))]};
      state.taskId = {};
      state.taskId = tckObject.datiTask.idTask;  //aka getIdTicket
      state.openedTicket = {};
      state.openedTicket = Object.assign({}, tckObject);
    },
    removeOpenedTicket: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == action.payload.taskId);
      state.allOpenedTicket.splice(index, 1);
      state.totalOpenedTicket -= 1;
      state.taskId = {};
      state.openedTicket = {};
      state.taskId = (state.totalOpenedTicket > 0) ? state.allOpenedTicket[state.totalOpenedTicket - 1].datiTask.idTask : '0';
      state.openedTicket = (state.totalOpenedTicket > 0) ? Object.assign({}, state.allOpenedTicket[state.totalOpenedTicket - 1]) : {};
    },
    setModifiedTicket: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == action.payload.taskId);
      state.allOpenedTicket[index] = Object.assign({}, action.modTicket);
    },
    resetModifiedTicket: (state) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.allOpenedTicket[index] = Object.assign({}, state.openedTicket);
      //identifica il ticket in lavorazione e resetta il risultato ovvero aggiorna redux
    },
    toogleLoading: (state, action) => {
      state.loading = action.payload;
    },
    toogleLoadingGrid: (state, action) => {
      state.loadingGrid = action.payload;
    },
    toggleIsSlowFormVisible: (state, action) => {
      state.isSlowFormVisible = action.payload;
    },
    getListaDanneggiati: (state, action) => {
      state.openedTicket.listaDanneggiati = action.payload;
    },
    getListaBeneficiarioLqInd: (state, action) => {
      state.openedTicket.listaBeneficiarioLqInd = action.payload
    },
    getListaSoggettiCause: (state, action) => {
      state.openedTicket.datiCausa.listaSoggetti = action.payload;
    },
    setListaSoggettiCausa: (state, action) => {
      state.openedTicket.datiCausa.listaSoggetti = action.payload;
    },
    getRamiSinistro: (state, action) => {
      state.openedTicket.listaRamiSinistro = action.payload;
    },
    VisibleAlertDangerMora: (state, action) => {
      state.openedTicket.alertDangerVisible = action.payload;
    },
    VisibleAlertWarningMora: (state, action) => {
      state.openedTicket.alertWarningVisible = action.payload;
    },
    VisibleAlertNoDataEvDataRis: (state, action) => {
      state.openedTicket.alertNoDataEvntoDataRis = action.payload;
    },
    setTitoloIsInCopertura: (state, action) => {
      state.openedTicket.isInCopertura = action.payload;
    },
    getGaranzieDanneggiato: (state, action) => {
      state.openedTicket.listaGaranzieDanneggiato = action.payload;
    },
    setRamoGaranzia: (state, action) => {
      state.openedTicket.ramoGaranziaPut = action.payload;
    },
    setRamoGaranziaDanneggiato: (state, action) => {
      state.openedTicket.ramoGaranziaDanneggiato = action.payload;
    },
    getPrecedentiSinistri: (state, action) => {
      state.openedTicket.precedentiSinistri = action.payload;
    },
    getRiserveComplessive: (state, action) => {
      state.openedTicket.riserveComplessive = action.payload;
    },
    getCauseIstruttoria: (state, action) => {
      // eslint-disable-next-line eqeqeq
      if (state.openedTicket?.datiTask?.idTask) {
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask === state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.causeIstruttoria);
      state.allOpenedTicket[index].causeIstruttoria = { ...tckObject };
      state.allOpenedTicket[index].causeIstruttoria = action.payload;}
    },
    getIncarichiIstruttoria: (state, action) => {
      state.openedTicket.incarichiIstruttoria = action.payload;
    },
    getValidazioniIstruttoria: (state, action) => {
      state.openedTicket.validazioniIstruttoria = action.payload;
    },
    addIncaricato: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.incaricato);
      state.allOpenedTicket[index].incaricato = { ...tckObject };
      state.allOpenedTicket[index].incaricato = action.payload;
    },
    removeIncaricato: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.datiIncarico?.incaricato);
      state.allOpenedTicket[index].datiIncarico.incaricato = { ...tckObject };
      state.allOpenedTicket[index].datiIncarico.incaricato = action.payload;
    },
    showAlertIncaricato: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.alertIncaricato);
      state.allOpenedTicket[index].alertIncaricato = { ...tckObject };
      state.allOpenedTicket[index].alertIncaricato = action.payload;
    },
    getListaIncaricatiInQuadro: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.listaIncaricatiInQuadro);
      state.allOpenedTicket[index].listaIncaricatiInQuadro = { ...tckObject };
      state.allOpenedTicket[index].listaIncaricatiInQuadro = action.payload;
    },

    getSlaCheck: (state, action) => {
      state.sla = action.payload;
    }, 

    resetSla: (state) => {
      state.sla = {}
    },

    setTipologiaIncaricato: (state, action) => {
      const index = state.allOpenedTicket.findIndex(
        (taskItem) => taskItem.datiTask.idTask === state.openedTicket?.datiTask?.idTask
      );
      const tckObject = Object.assign({}, state.openedTicket?.datiIncarico);
      state.allOpenedTicket[index].datiIncarico = { ...tckObject };
      state.allOpenedTicket[index].datiIncarico.tipologia = action.payload;
    },

   getListaIncaricatoNoQuadro: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.listaIncaricatoNoQuadro);
      state.allOpenedTicket[index].listaIncaricatoNoQuadro = { ...tckObject };
      state.allOpenedTicket[index].listaIncaricatoNoQuadro = action.payload;
    },
    setIdCauseCollegate: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.idsCauseCollegate);
      state.allOpenedTicket[index].idsCauseCollegate = { ...tckObject };
      state.allOpenedTicket[index].idsCauseCollegate = action.payload;
    },
    getRiservaSpeseIncarico: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.riservaSpeseIncarico);
      state.allOpenedTicket[index].riservaSpeseIncarico = { ...tckObject };
      state.allOpenedTicket[index].riservaSpeseIncarico = action.payload;
    },
    setMotivazioneIncarico: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.motivazioneIncarico);
      state.allOpenedTicket[index].motivazioneIncarico = { ...tckObject };
      state.allOpenedTicket[index].motivazioneIncarico = action.payload;
    },

    setDataRestituzioneIncarico: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      if (index !== -1) {
        state.allOpenedTicket[index].datiIncarico = {
          ...state.allOpenedTicket[index].datiIncarico,
          dataRestituzione: action.payload instanceof Date ? action.payload.toISOString() : action.payload
        };
      }
    },

    setSlaAndStato: (state, action) => {
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask === state.openedTicket?.datiTask?.idTask);
      if (index !== -1) {
        state.allOpenedTicket[index].datiIncarico = {
          ...state.allOpenedTicket[index].datiIncarico,
          sla: action.payload.sla,
          statoSla: action.payload.statoSla
        };
      }
    },

    setDeroga: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      if (index !== -1) {
        state.allOpenedTicket[index].datiIncarico = {
          ...state.allOpenedTicket[index].datiIncarico,
          deroga: action.payload
        };
      }
    },
    
    setMotivazioneIncaricoSla: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      if (index !== -1) {
        state.allOpenedTicket[index].datiIncarico = {
          ...state.allOpenedTicket[index].datiIncarico,
          motivazioneSla: action.payload
        };
      }
    },
    setMotivazioneIncaricoNoQuadro: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.motivazioneIncaricoNoQuadro);
      state.allOpenedTicket[index].motivazioneIncaricoNoQuadro = { ...tckObject };
      state.allOpenedTicket[index].motivazioneIncaricoNoQuadro = action.payload;
    },
    setNotaRiserveAuthority: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.notaRiserveAuthority);
      state.allOpenedTicket[index].notaRiserveAuthority = { ...tckObject };
      state.allOpenedTicket[index].notaRiserveAuthority = action.payload;
    },
    setNotaRiserveAuthorityPagamento: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.notaRiserveAuthorityPagamento);
      state.allOpenedTicket[index].notaRiserveAuthorityPagamento = { ...tckObject };
      state.allOpenedTicket[index].notaRiserveAuthorityPagamento = action.payload;
    },
    setDisableComponentIncarico: (state, action) =>{
       // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.disableComponentIncarico);
      state.allOpenedTicket[index].disableComponentIncarico = { ...tckObject };
      state.allOpenedTicket[index].disableComponentIncarico = action.payload;
    },
    getListaSearchPolizza: (state, action) =>{
      state.openedTicket.listaSearchPolizza = action.payload;
    },
    getListaSearchPolizzaCollettiva : (state, action) =>{
      state.openedTicket.listaSearchPolizzaCollettiva = action.payload;
    },
    getListaBeniCollettiviAmministrativi : (state, action) =>{
      state.openedTicket.listaBeniCollettiviAmministrativi = action.payload;
    },
    getListaSearchCollettiva: (state, action) => {
      state.openedTicket.listaSearchCollettiva = action.payload;
    },
    setPolizza: (state, action) =>{
        state.openedTicket.setPolizza = action.payload;
    },
    setPolizzaTempAmministrativo: (state, action) =>{
      state.openedTicket.setPolizzaTempAmministrativo = action.payload;
    },
    setNotaPromemoria: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.datiAppuntamento?.nota);
      state.allOpenedTicket[index].datiAppuntamento.nota = { ...tckObject };
      state.allOpenedTicket[index].datiAppuntamento.nota = action.payload;
    },
    setDescrizionePromemoria: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.datiAppuntamento?.descrizione);
      state.allOpenedTicket[index].datiAppuntamento.descrizione = { ...tckObject };
      state.allOpenedTicket[index].datiAppuntamento.descrizione = action.payload;
    },
    getListaPagamentiIstruttoria: (state, action) =>{
      state.openedTicket.listaPagamenti = action.payload;

    },
    setAlertPagamentiSpese: (state, action) =>{
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.alertSimplePagamanetiSpese);
      state.allOpenedTicket[index].alertSimplePagamanetiSpese = { ...tckObject };
      state.allOpenedTicket[index].alertSimplePagamanetiSpese = action.payload;
    },

    setAlertPagamentiIndennizzoBeneficiari: (state, action) =>{
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.alertSimplePagamentiIndennizzoBeneficiari);
      state.allOpenedTicket[index].alertSimplePagamentiIndennizzoBeneficiari = { ...tckObject };
      state.allOpenedTicket[index].alertSimplePagamentiIndennizzoBeneficiari = action.payload;
    },

    getListaDanneggiatiJmil: (state, action) =>{
      state.openedTicket.listaDanneggiatiJmil = action.payload?.danneggiati;
    },
    setImportoSpesa: (state, action) =>{
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.datiPagamento.pagamentoSpesa.importo);
      state.allOpenedTicket[index].datiPagamento.pagamentoSpesa.importo = { ...tckObject };
      state.allOpenedTicket[index].datiPagamento.pagamentoSpesa.importo = action.payload;
    },
    setIncaricoSpese: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.datiPagamento?.pagamentoSpesa?.incaricato);
      state.allOpenedTicket[index].datiPagamento.pagamentoSpesa.incaricato = { ...tckObject };
      state.allOpenedTicket[index].datiPagamento.pagamentoSpesa.incaricato = action.payload;
    },
   getGaranziePagamentoIndennizzo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.garanziePagamentoIndennizzo);
      state.allOpenedTicket[index].garanziePagamentoIndennizzo = { ...tckObject };
      state.allOpenedTicket[index].garanziePagamentoIndennizzo = action.payload;
    } ,
    setGaranziePagamentoIndennizzo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.garanziePagamentoIndennizzoValue);
      state.allOpenedTicket[index].garanziePagamentoIndennizzoValue = { ...tckObject };
      state.allOpenedTicket[index].garanziePagamentoIndennizzoValue = action.payload;
    },
    setDanneggiatoIdPagamentoIndennizzo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.danneggiatoIdPagamentoIndennizzo);
      state.allOpenedTicket[index].danneggiatoIdPagamentoIndennizzo = { ...tckObject };
      state.allOpenedTicket[index].danneggiatoIdPagamentoIndennizzo = action.payload;
    },
    setFormEnable: (state, action) => {
      state.openedTicket.formEnable= action.payload;
    },
    getListaBeneficiariInennizzo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.listaBeneficiariIndennizzo);
      state.allOpenedTicket[index].listaBeneficiariIndennizzo = { ...tckObject };
      state.allOpenedTicket[index].listaBeneficiariIndennizzo = action.payload;
    },
    setDatiValidazione: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.datiValidazionePut);
      state.allOpenedTicket[index].datiValidazionePut = { ...tckObject };
      state.allOpenedTicket[index].datiValidazionePut = action.payload; 
    },
    setIdValidazione: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.idEsitazioneValidazione);
      state.allOpenedTicket[index].idEsitazioneValidazione = { ...tckObject };
      state.allOpenedTicket[index].idEsitazioneValidazione = action.payload; 
    },
    setImportoPagamento: (state, action)=>{
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.importoPagmento);
      state.allOpenedTicket[index].importoPagmento = { ...tckObject };
      state.allOpenedTicket[index].importoPagmento = action.payload; 
    },
    setSelectedRadioIdPolizza: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.selectedRadioIdPolizza = action.payload; 
      state.allOpenedTicket[index].selectedRadioIdPolizza = action.payload; 
    }, 
    setDuplicatoId: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.duplicatoId = action.payload; 
      state.allOpenedTicket[index].duplicatoId = action.payload; 
    },    
    getDatiUtente: (state, action) => {
      state.userData = {};
      state.userData = { ...{}, ...action.payload };
    },
    setGridConfigJson: (state, action) => {
      const datiConfig = state.userData.gridConfigJson
      if(datiConfig?.length > 0){
        state.userData.gridConfigJson = action.payload;  
      }
    },
    setGridFilterConfigJson: (state, action) => {
      const datiConfig = state.userData.gridConfigJson
      if(datiConfig?.length > 0){
        state.userData.gridFilterConfigJson = action.payload;  
      }
    },
    setIdTitoloAmmOpenSinistro: (state, action) => {//
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.idTitoloAmmOpenSinistro = action.payload; 
      state.allOpenedTicket[index].idTitoloAmmOpenSinistro = action.payload; 
    },
    setPagamentiSpese: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.datiPagamento.pagamentoSpesa = action.payload;
      state.allOpenedTicket[index].datiPagamento.pagamentoSpesa = action.payload; 
    },
    setEmailMittenteSelectState: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.emailMittente = action.payload;
      state.allOpenedTicket[index].emailMittente = action.payload;
    },
    setAssegnatarioSelecState: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.assegnatarioSinistroAperto = action.payload;
      state.allOpenedTicket[index].assegnatarioSinistroAperto = action.payload;
    },
    setListDuplicati: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.listaDuplicati = action.payload;
      state.allOpenedTicket[index].listaDuplicati = action.payload; 
    },
    setEsitoPerPostEsitazione: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.esitoPerPostEsitazione = action.payload;
      state.allOpenedTicket[index].esitoPerPostEsitazione = action.payload;
    },
    setRiservaNettaLorda: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.datiDenuncia.gestioneSinistro = action.payload;
      state.allOpenedTicket[index].datiDenuncia.gestioneSinistro = action.payload;
    },
    getCalcoloMassimali: (state, action) => {
      state.calcoloMassimaliExtOpnenedticket = action.payload;
    },
    setRecuperoFranchigia: (state, action) => {
      state.recuperoFranchigia = action.payload;
    },
    setTotaleRecuperoFranchigia: (state, action) => {
      state.totaleRecuperoFranchigia = action.payload;
    },
    setRecuperoFranchigiaIstruttoria: (state, action) => {
      state.openedTicket.recuperoFranchigiaIstruttoria = action.payload;
    },
    toggleIsButtonEnabledMassimaleGaranzieVisible: (state, action)=> {
      state.isButtonEnabledMassimaleGaranzieVisible = action.payload;
    },
    setListaBeneficiariRecuperoFranchigia: (state, action) => {
      state.listaBeneficiariRecuperoFranchigia = action.payload;
    },
    setListaBeneficiariRecuperoFranchigiaTable: (state, action) => {
      state.listaBeneficiariRecuperoFranchigiaTable = action.payload;
    },
    setSelectRecuperoFranchigia: (state, action) => {
      state.openedTicket.selectRecuperoFranchigia  = action.payload;
    },
    getListaAllegatiPagamentoIndennizzo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.listaAllegatiPagamentoIndennizzo = action.payload;
      state.allOpenedTicket[index].listaAllegatiPagamentoIndennizzo = action.payload;
    },
    getListaAllegatiQuietanza: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.listaAllegatiQuietanza = action.payload;
      state.allOpenedTicket[index].listaAllegatiQuietanza = action.payload;
    },
    getAllegatoPagamentoIndennizzo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.allegatoPagamentoIndennizzo = action.payload;
      state.allOpenedTicket[index].allegatoPagamentoIndennizzo = action.payload;
    },
    getListaAllegatiPagoPa: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.listaAllegatoPagoPA = action.payload;
      state.allOpenedTicket[index].listaAllegatoPagoPA = action.payload;
    },
    getListaAllegatiPagamentoSpese: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.listaAllegatiPagamentoSpese = action.payload;
      state.allOpenedTicket[index].listaAllegatiPagamentoSpese = action.payload;
    },
    setQuietanzaSeparata: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.quietanzaSeparata);
      state.allOpenedTicket[index].quietanzaSeparata = { ...tckObject };
      state.allOpenedTicket[index].quietanzaSeparata = action.payload; 
    },
    popolaDatiShellClaim: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.datiShellClaim);
      state.allOpenedTicket[index].datiShellClaim = { ...tckObject };
      state.allOpenedTicket[index].datiShellClaim = action.payload; 
    },
    aggiornaCalcoliShellClaim: (state) => {
       // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index].datiShellClaim;
      if (dati) {
        dati.riservaIndennizzoNostraQuota = (dati.nostraQuota / 100) * dati.riservaIndennizzo;
        dati.riservaSpeseNostraQuota = (dati.nostraQuota / 100) * dati.riservaSpese;
        dati.riservaTotale = dati.riservaIndennizzo + dati.riservaSpese;
        dati.riservaTotaleNostraQuota = dati.riservaIndennizzoNostraQuota + dati.riservaSpeseNostraQuota;
        dati.pagatoTotaleNostraQuota = dati.pagatoIndennizzoNostraQuota + dati.pagatoSpeseNostraQuota
        state.allOpenedTicket[index].datiShellClaim = {...state.allOpenedTicket[index].datiShellClaim, ...dati };
        state.openedTicket.datiShellClaim = {...state.openedTicket?.datiShellClaim, ...dati };
      }
    },
    setuserDataShellClaimLiquidatore: (state,action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index];
      if (dati) {
      const {codIspettoratoJmil} = action.payload;
      const {codLiquidatoreJmil} = action.payload;
      dati.userDataShellClaimLiquidatore = {codIspettoratoJmil,codLiquidatoreJmil};
      
      state.allOpenedTicket[index] = {...state.allOpenedTicket[index], ...dati };
      state.openedTicket = {...state.openedTicket, ...dati };
      }
    },

    setDescrAssicuratoDatiShellClaim: (state, action) =>{
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index].datiShellClaim;
      if(dati){
        dati.assicurato = action.payload;
      }
    },
    setIdTitoloBeneCollettiveShellClaim: (state, action) =>{
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index].datiShellClaim;
      if(dati){
        dati.idBeneCollettivo = action.payload;
      }
    },
    setIdTitoloAmmShellClaim: (state, action) =>{
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index].datiShellClaim;
      if(dati){
        dati.idTitoloAmministrativo = action.payload;
      }
    },
    setSinistroIngente: (state,action) => {
       // eslint-disable-next-line eqeqeq
       const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
       if(state.openedTicket.datiDenuncia){
         state.openedTicket.datiDenuncia.sinistroIngente = action.payload;
         state.allOpenedTicket[index].datiDenuncia.sinistroIngente = action.payload; 
        }
    },
    setValidazioneNotaResponsabile: (state,action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.datiValidazione.notaResponsabile = action.payload;
      state.allOpenedTicket[index].datiValidazione.notaResponsabile = action.payload;
    },
    setRiservaDiretta: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.datiIncarico.gestioneRiserva=action.payload;
      state.allOpenedTicket[index].datiIncarico.gestioneRiserva = action.payload;
    },
    setBeneCollettiveAmministrativoToDannObj: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      state.openedTicket.beneCollettivoAmministrativoToDann = action.payload;
      state.allOpenedTicket[index].beneCollettivoAmministrativoToDann = action.payload;
    },
    setIdBeneAssicuratoAmministrativo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const datiDenunciaWrite = state.allOpenedTicket[index].datiDenuncia;
      const datiDenunciaRead = state.openedTicket.datiDenuncia;
      if(datiDenunciaRead && datiDenunciaWrite){
        datiDenunciaRead.idBene = action.payload;
        datiDenunciaWrite.idBene = action.payload;
      }
    },
    multiValutaPagamentoSpese: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.multiValutaPagSpese);
      state.allOpenedTicket[index].multiValutaPagSpese = { ...tckObject };
      state.allOpenedTicket[index].multiValutaPagSpese = {...action.payload}; 
    },
    multiValutaPagamentoIndennizzo: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket?.multiValutaPagamentoIndennizzo);
      state.allOpenedTicket[index].multiValutaPagamentoIndennizzo = { ...tckObject };
      state.allOpenedTicket[index].multiValutaPagamentoIndennizzo = {...action.payload}; 
    },
    setCcMail: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex(taskItem => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      if (index !== -1) {
        state.allOpenedTicket[index].ccMail = action.payload;  // creo array con le mail inserite
        state.allOpenedTicket[index].ccMailValid = true;//inizio con true
        state.allOpenedTicket[index].ccMailErrors = [];//inizializzo array vuoto
      }
    },
    validateCcMail: (state) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex(taskItem => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      if (index !== -1) {
        const dati = state.allOpenedTicket[index];
        const uniqueEmails = [...dati.ccMail];//creo array con la mail inserita
        const validEmails = uniqueEmails.filter(email => profileValidation.isValidEmailNew(email));//filtro le mail valide
        const invalidEmails = uniqueEmails.filter(email => !profileValidation.isValidEmailNew(email));//filtro le mail non valide
        dati.ccMailValid = invalidEmails.length === 0;//se la lunghezza delle mail non valide è 0 allora è true
        dati.ccMailErrors = invalidEmails;//salvo le mail non valide
        dati.validCcMails = validEmails; //salvo  nello stato le mail valide
      }
    },
    setNotaEsitoInAgenda: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket);
      state.allOpenedTicket[index] = { ...tckObject };
      const dati = state.allOpenedTicket[index];
      if(dati){
        dati.notaEsitoInAgenda = action.payload;
      }
    },
    setNotaAppuntamento: (state, action) => {
      // eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const tckObject = Object.assign({}, state.openedTicket);
      state.allOpenedTicket[index] = { ...tckObject };
      const dati = state.allOpenedTicket[index];
      if(dati){
        dati.notaAppuntamento = action.payload;
      }
    },
    setOperatori: (state, action) => {
      //eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket.datiTask.idTask);
      const tckObject = Object.assign({}, state.openedTicket);
      state.allOpenedTicket[index] = { ...tckObject };
      const dati = state.allOpenedTicket[index];
      if(dati){
        dati.listaOperatori = action.payload;
      }
    },
    setRiassegnaoperatoreSinistro: (state, action) => {
      //eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket.datiTask.idTask);
      const dati = state.allOpenedTicket[index];
      if(dati){
        dati.operatoreRiassegnato = action.payload;
        state.allOpenedTicket[index] = {...dati, ...action.payload};
      }
    },
    setObjNewTaskDenuncia: (state, action) => {
      state.objNewTask = {};
      state.objNewTask = { ...{}, ...action.payload };
    },
    setIdRadioButtonNewTask: (state, action) => {
      state.idRadioButtonNewTask = action.payload;
    },
    setDerogaAllegato: (state, action) => {
      //eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index].datiPagamento?.pagamentoIndennizzo;
      if(dati){
        dati.derogaAllegato = action.payload;
        state.allOpenedTicket[index].datiPagamento.pagamentoIndennizzo = {...dati};
      }
    },
    getValidazioneIban: (state, action) => {
      //eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index];
      if(dati){
        dati.validazioneIban = action.payload;
        state.allOpenedTicket[index] = {...dati};
      }
    },
    setConfigLanguage: (state, action) => {
      //eslint-disable-next-line eqeqeq
      state.language = action.payload;
      localStorage.setItem('lang', action.payload.language);
    },
    setListaCodiceEvento: (state, action) => {
      //eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const dati = state.allOpenedTicket[index].datiDenuncia;
      const datiRead = state.openedTicket.datiDenuncia;
      if(dati && datiRead) {
        dati.listaCodiceEvento = action.payload;
        datiRead.listaCodiceEvento = action.payload;
        state.allOpenedTicket[index].datiDenuncia = {...dati};
        state.openedTicket.datiDenuncia = {...datiRead};
      }
    },
    setAlertInventarioRiserva: (state, action) => {
      //eslint-disable-next-line eqeqeq
      const index = state.allOpenedTicket.findIndex((taskItem) => taskItem.datiTask.idTask == state.openedTicket?.datiTask?.idTask);
      const datiWrite = state.allOpenedTicket[index]
      if(datiWrite){
        datiWrite.alertInventarioRiserva = action.payload;
        state.allOpenedTicket[index] = {...datiWrite};
      }
    }
  }
});

export const { 
  setErrorSla,
  getTicketsLavorabili,
  getTicket,
  getTicketTree,
  getTicketsAssegnate,
  resetSla,
  setVisibilityFilter,
  SearchTicket,
  DeleteTicket, 
  setOpenedTicketClaimData,
  setOpenedTicketAdminData,
  setOpenedTicketAdminDataRender,
  setOpenedTicketNPolizza,
  clearDatiDenuncia,
  setOpenedTicketDatiCausa,
  setOpenedTicketDatiIncarico,
  setDenunciaMotivazione,
  setIdTicketInCharge,
  removeIdTicketInCharge,
  addOpenedTicket,
  setOpenedTicketChangePolizza,
  addTaskToNavSinstro,
  addOpenedTicketCached,
  removeOpenedTicket,
  setModifiedTicket,
  resetModifiedTicket,
  toogleLoading,
  toogleLoadingGrid,
  toggleIsSlowFormVisible,
  getListaDanneggiati,
  getListaBeneficiarioLqInd,
  getListaSoggettiCause,
  setListaSoggettiCausa,
  getRamiSinistro,
  getSlaCheck,
  setDataRestituzioneIncarico,
  setMotivazioneIncaricoSla,
  setTipologiaIncaricato,
  VisibleAlertDangerMora,
  VisibleAlertWarningMora, 
  VisibleAlertNoDataEvDataRis, 
  setTitoloIsInCopertura,
  getGaranzieDanneggiato,
  setRamoGaranzia,
  setRamoGaranziaDanneggiato,
  getPrecedentiSinistri,
  getRiserveComplessive,
  getCauseIstruttoria,
  getIncarichiIstruttoria,
  getValidazioniIstruttoria,
  addIncaricato,
  removeIncaricato,
  showAlertIncaricato,
  setTipoIncaricato,
  getListaIncaricatiInQuadro,
  getListaIncaricatoNoQuadro,
  setIdCauseCollegate,
  getRiservaSpeseIncarico,
  setMotivazioneIncarico,
  setMotivazioneIncaricoNoQuadro,
  setNotaRiserveAuthority,
  setNotaRiserveAuthorityPagamento,
  setDisableComponentIncarico,
  getListaSearchPolizza,
  getListaSearchPolizzaCollettiva,
  getListaBeniCollettiviAmministrativi,
  getListaSearchCollettiva,
  setPolizza,
  setPolizzaTempAmministrativo,
  setNotaPromemoria,
  setDescrizionePromemoria,
  getListaPagamentiIstruttoria,
  setAlertPagamentiSpese,
  getListaDanneggiatiJmil,
  setImportoSpesa,
  setDeroga,
  setIncaricoSpese,
  getGaranziePagamentoIndennizzo,
  setGaranziePagamentoIndennizzo,
  setDanneggiatoIdPagamentoIndennizzo,
  setFormEnable,
  getListaBeneficiariInennizzo,
  setAlertPagamentiIndennizzoBeneficiari,
  setDatiValidazione,
  setIdValidazione,
  setImportoPagamento,
  setSelectedRadioIdPolizza,
  setSlaAndStato,
  setDuplicatoId,
  getDatiUtente,
  setGridConfigJson,
  setGridFilterConfigJson,
  setIdTitoloAmmOpenSinistro,
  setPagamentiSpese,
  setEmailMittenteSelectState,
  setAssegnatarioSelecState,
  setListDuplicati,
  setEsitoPerPostEsitazione,
  setRiservaNettaLorda,
  getCalcoloMassimali,
  setRecuperoFranchigia,
  setRecuperoFranchigiaIstruttoria,
  setTotaleRecuperoFranchigia,
  setListaBeneficiariRecuperoFranchigia,
  toggleIsButtonEnabledMassimaleGaranzieVisible,
  setListaBeneficiariRecuperoFranchigiaTable,
  setSelectRecuperoFranchigia,
  getListaAllegatiPagamentoIndennizzo,
  getAllegatoPagamentoIndennizzo,
  getListaAllegatiQuietanza,
  getListaAllegatiPagoPa,
  getListaAllegatiPagamentoSpese,
  setQuietanzaSeparata,
  aggiornaCalcoliShellClaim,
  setDescrAssicuratoDatiShellClaim,
  setuserDataShellClaimLiquidatore,
  setIdTitoloBeneCollettiveShellClaim,
  setIdTitoloAmmShellClaim,
  popolaDatiShellClaim,
  setSinistroIngente,
  setValidazioneNotaResponsabile,
  setRiservaDiretta,
  setBeneCollettiveAmministrativoToDannObj,
  setIdBeneAssicuratoAmministrativo,
  multiValutaPagamentoSpese,
  multiValutaPagamentoIndennizzo,
  setCcMail,
  validateCcMail,
  setNotaEsitoInAgenda,
  setNotaAppuntamento,
  setOperatori,
  setRiassegnaoperatoreSinistro,
  setObjNewTaskDenuncia,
  setIdRadioButtonNewTask,
  setDerogaAllegato,
  getValidazioneIban,
  setConfigLanguage,
  setListaCodiceEvento,
  setAlertInventarioRiserva
} = TicketSlice.actions;

/**
 * Get lista Ticket Lavorabili che, al return viene popolato ticketsLavorabili tramiteil metododo getTicketsLavorabili
 * @function fetchTicketsLavorabili
 * @returns ritorna lista Ticket Lavorabili 
*/
export const fetchTicketsLavorabili = () => async (dispatch) => {
  dispatch(toogleLoadingGrid(true));
  const API_URI_CALL = `${API_URL}/api/v1/bff/task/lista-lavorabili`;

  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res', res);
  console.log('err', err);
  dispatch(toogleLoadingGrid(false));
  return dispatch(getTicketsLavorabili(res.data));
};

/**
 * Get lista Ticket Lavorabili che, al return viene popolato ticketsAssegnate tramite il metododo getTicketsAssegnate
 * @function fetchTicketsAssegnate
 * @returns ritorna lista Ticket Assegnati 
*/
export const fetchTicketsAssegnate = () => async (dispatch) => {
  dispatch(toogleLoadingGrid(true));
  const API_URI_CALL = `${API_URL}/api/v1/bff/task/lista-assegnate`;

  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('res', res);
  console.log('err', err);
  dispatch(toogleLoadingGrid(false));
  return dispatch(getTicketsAssegnate(res.data));
};

/**
 * Get Dettaglio Ticket, contiene tutti i dati neccesari alla compialzione del task. E viene salvato nel nodo openedTicket e al nodo allOpenedTicket / In caso di Cache Attivo viene usato il metodo addOpenedTicketCached
 * @function fetchTicket
 * @param {String} taskId id del task
*/
export const fetchTicket = (taskId) => async (dispatch) => {
  dispatch(toogleLoading(true));
  dispatch(getTicket({}));

  if (taskId?.cache === false || taskId?.cache === undefined) {
    //const fetchData = async () => axiosInstance.get('/api/data/singleTicket/singleTicketData'); taskId.taskId
    const API_URI_CALL = `${API_URL}/api/v1/bff/task/dettaglio/${taskId.taskId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    dispatch(addOpenedTicket(res.data));
    dispatch(getTicket(res.data));
    const ObjectTask = {
      taskId: taskId?.taskId,
      nSinistro: res?.data?.datiDenuncia?.nSinistro,
    };
    dispatch(addTaskToNavSinstro(ObjectTask))
    
  } else {
    setTimeout(() => {
      const datiTask = {"idTask": taskId.taskId};
      dispatch(addOpenedTicketCached({datiTask}));
  }, 1000);
  }

  return dispatch(toogleLoading(false));
};

/**
 * Get Dettaglio Ticket, contiene tutti i dati neccesari alla compialzione del task. In questo caso salviamo la response nel metodo getTicketTree che popola il nodo datiTaskFigli per l'abero laterale
 * @function fetchTicketTree
 * @param {String} taskId ID del task
 * @returns getTicketTree dati albero laterale
*/
export const fetchTicketTree = (taskId) => async (dispatch) => {
 // dispatch(toogleLoading());
  
    //const fetchData = async () => axiosInstance.get('/api/data/singleTicket/singleTicketData'); taskId.taskId
    const API_URI_CALL = `${API_URL}/api/v1/bff/task/dettaglio/${taskId.taskId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await  executor.executeAsync(fetchData);

    console.log('err', err);

  return  dispatch(getTicketTree(res.data));
     
 // return dispatch(toogleLoading());
};

export const rebuildModTicket = (taskId) => async (dispatch) => {
  dispatch(toogleLoading(true));
  dispatch(getTicket({}));

  //const fetchData = async () => axiosInstance.get('/api/data/singleTicket/singleTicketData'); taskId.taskId
  const API_URI_CALL = `${API_URL}/api/v1/bff/task/dettaglio/${taskId.taskId}`;
  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('err', err);


  dispatch(setOpenedTicketChangePolizza(res.data));
  dispatch(getTicket(res.data));

  // dispatch(resetModifiedTicket());
  return dispatch(toogleLoading(false));
};

/**
 * Get Lista Danneggiati, i dati della response tramite il metodo getListaDanneggiati vengono salvati nel nodo listaDanneggiati, viene anche salvato il ramo completo tramite setRamoGaranziaDanneggiato nel nodo ramoGaranziaDanneggiato e il nodo con due cifre tramite il metodo setRamoGaranzia nel nodo ramoGaranziaPut
 * @function fetchListaDanneggiati
 * @param {uuID} idSinistro ID sinistro
 * @returns getListaDanneggiati lista Danneggiati
*/
export const fetchListaDanneggiati = (idSinistro) => async (dispatch) => {
  if (idSinistro) {
    //dispatch(toogleLoading());
    //dispatch(toggleIsButtonEnabledMassimaleGaranzieVisible(false));
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/lista/danneggiati/${idSinistro}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    if (res?.data?.length>0) {
       //ramoGaranziaDanneggiato:"0013"
       dispatch(setRamoGaranziaDanneggiato(res.data[0].ramoGaranzia));
    
      const inputValue = res?.data[0]?.ramoGaranzia;
      if (inputValue != null) {
      let outputValue = '';
      //Prendo ultime due cifre del ramo
           if (parseInt(inputValue, 10) < 10) {
             outputValue = inputValue.slice(-2);
           } else {
             for (let i = 0; i < inputValue.length; i++) {
               const digit = parseInt(inputValue[i], 10);
               if (digit > 0) {
                 outputValue += digit;
               }
             }
           }
      //ramoGaranziaPut:"13"
      dispatch(setRamoGaranzia(outputValue));}

    }
    return dispatch(getListaDanneggiati(res.data));
    //dispatch(toogleLoading());
    //return dispatch(toggleIsButtonEnabledMassimaleGaranzieVisible(true));
  }
  //return dispatch(toggleIsButtonEnabledMassimaleGaranzieVisible(true));
  return null;
};

/**
 * Ritorna la lista dei beneficiari 
 * @param {String} nPolizza 
 * @returns lista beneficiari pagamento indennizzo
*/
export const fetchListaBeneficiarioLqInd = (nPolizza, sinistroId) => async (dispatch) => {
  if(nPolizza !== "" && nPolizza !== null && nPolizza !== undefined){
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/lista/beneficiario-indennizzo/${sinistroId}/${nPolizza}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);
    if (res?.data?.length>0){
      return dispatch(getListaBeneficiarioLqInd(res.data))
    }
  }
  return null
}

/**
 * Get Lista Rmi che popola la selec Rami tramite il metodo getRamiSinistro e popola il nodo listaRamiSinistro
 * @function fetchListaRamiSinistro
 * @param {String} nPolizza Numero Polizza
 * @param {String} idSinistro ID Sinistro
 * @param {String} idTitoloAmministrativo ID Titolo amministrativo
 * @param {String} idBene ID Bene
 * @returns getRamiSinistro
*/
export const fetchListaRamiSinistro = (nPolizza, idSinistro, idTitoloAmministrativo, idBene) => async (dispatch) => {
  const controlloForIdAmm = "0";
  // eslint-disable-next-line eqeqeq
  if (nPolizza && nPolizza !== 'N/A' && idSinistro && ((idTitoloAmministrativo && idTitoloAmministrativo != (controlloForIdAmm)) || idBene)) {
    let queryParamPolizza;
		if(idBene){
      queryParamPolizza = `?idBene=${idBene}`
			
		}else {
			queryParamPolizza = `?idTitolo=${idTitoloAmministrativo}`
		}
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/rami/${nPolizza}/${idSinistro}${queryParamPolizza}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getRamiSinistro(res.data));
  }
  return null;
};

/**
 * Get Lista Garanzie per Danneggiato, ritorna le garanzie(prestazioni) tramite il metodo getGaranzieDanneggiato che salverà i dati nel nodo listaGaranzieDanneggiato. In base all idBene verrà utilizzato un Paramquery diverso idBene=${idBene} o idTitolo=${idTitoloJMIL}
 * @function fetchListaGaranzieDanneggiato
 * @param {String} taskId ID del task
 * @param {String} danneggiatoId ID del Danneggiato
 * @param {String} codiceRamo codice del ramo
 * @param {String} idTitoloJMIL ID Titlo amministrativo JMIL
 * @param {String} idBene ID del Bene
 * @returns getGaranzieDanneggiato ritorna lista garanzie
*/
export const fetchListaGaranzieDanneggiato = (taskId, danneggiatoId, codiceRamo, idTitoloJMIL, idBene) => async (dispatch) => {

  if (taskId && danneggiatoId && codiceRamo) {
     let queryParamPolizza;
		if(idBene){
      queryParamPolizza = `idBene=${idBene}`
			
		}else {
			queryParamPolizza = `idTitolo=${idTitoloJMIL}`
		}
    //dispatch(toogleLoading());
    /* Da smontare e rifare correttamente */
    const onlyDigits = parseInt(codiceRamo, 10); // Rimuovo tutte le lettere dalla stringa

    const newStringCodiceRamo = `00${onlyDigits.toString()}`;

    const subStringRamo = newStringCodiceRamo.slice(-2);
  
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/garanzie/${taskId}/${danneggiatoId}?codiceRamo=${subStringRamo}&${queryParamPolizza}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

     dispatch(getGaranzieDanneggiato(res.data));
    // dispatch(toogleLoading());
    return dispatch(toggleIsButtonEnabledMassimaleGaranzieVisible(true));
  }
  return null;
};

/**
 * Get Lista Precedenti Sinistri, tramite il metodo getPrecedentiSinistri i dati vengono dalvati nel nodo precedentiSinistri per popolare la tabella Precendenti Sinistri
 * @function fetchPrecendentiSinistri
 * @param {String} nPolizza Numero Polizza
 * @param {String} idSinistro ID Sinistro
 * @returns getPrecedentiSinistri popola la tabella Precendenti Sinistri
*/
export const fetchPrecendentiSinistri = (nPolizza, idSinistro) => async (dispatch) => {
  if (nPolizza && nPolizza !== 'N/A' && idSinistro) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/lista/sinistri-precedenti/${nPolizza}/id/${idSinistro}`;
    //this is shit
    const uuID = crypto.randomUUID();
    const userToken = JSON.parse(localStorage.getItem('user'));
    const idFlussoLocal = localStorage.getItem('idFlusso');

    if (userToken) {
      axiosInstance.defaults.headers.common = { Authorization: `Bearer ${userToken.access_token}`, requestId: uuID, page: 0, size: 100, idFlusso:idFlussoLocal };
    }
    //this is shit
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getPrecedentiSinistri(res.data));
  }
  return null;
};

/**
 * Get Riserve complessive, tramite il metodo getRiserveComplessive viene popolato il nodo riserveComplessive, per popolare il componente delle riserve complessive
 * @function fetchRiserveComplessive
 * @param {String} idSinistro ID Sinistro
 * @returns getRiserveComplessive popolare risreve complessive
*/
export const fetchRiserveComplessive = (idSinistro,quota) => async (dispatch) => {
  if (idSinistro && quota) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/lista/riserve-complessive/${idSinistro}`;
    //this is shit
    const userToken = JSON.parse(localStorage.getItem('user'));
    
    if (userToken) {
      axiosInstance.defaults.headers.common = { Authorization: `Bearer ${userToken.access_token}`, quota }; //quota da capire se deve essere dinamico
    }
    //this is shit
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err getRiserveComplessive', err);

    return dispatch(getRiserveComplessive(res.data));
  }
  return null;
};

/**
 * Get Lista Cause Istruttoria, tramite il metodo getCauseIstruttoria viene popolato il nodo causeIstruttoria, per popolare la tabella cause collegate al sinistro
 * @function fetchCauseIstruttoria
 * @param {String} idSinistro ID Sinistro
 * @returns getCauseIstruttoria popola tabella CauseIstruttoria
*/
export const fetchCauseIstruttoria = (idSinistro) => async (dispatch) => {
  if (idSinistro) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/cause/lista/${idSinistro}?size=100&page=0`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getCauseIstruttoria(res.data));
  }
  return null;
};

/**
 * Get Lista Soggetti Causa, tramite il metodo getListaSoggettiCause, viene popolato il nodo listaSoggetti, serve per popolare la select dei possibili soggetti coinvoliti in una casua nel task Cause
 * @function fetchListaSoggettiCausa
 * @param {String} causaId ID Causa
 * @returns getListaSoggettiCause popola select Soggetti Causa
*/
export const fetchListaSoggettiCausa = (causaId) => async (dispatch) => {
  if (causaId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/cause/soggetti/${causaId}?size=20&page=0`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getListaSoggettiCause(res.data));
  }
  return null;
};

/**
 * Get Lista Incarichi in Istruttoria, tramite il metodo getIncarichiIstruttoria viene popolato il nodo incarichiIstruttoria, popola tabella Incarichi in Istruttoria
 * @function fetchIncarichiIstruttoria
 * @param {String} idSinistro ID Sinistro
 * @returns getIncarichiIstruttoria popola tabella Incarichi in Istruttoria
*/
export const fetchIncarichiIstruttoria = (idSinistro) => async (dispatch) => {
  if (idSinistro) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/incarichi/lista/${idSinistro}?size=100&page=0`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getIncarichiIstruttoria(res.data));
  }
  return null;
};

/**
 * Get Lista Incarichi in Quadro, tramite il metodo getListaIncaricatiInQuadro viene popolato il nodo listaIncaricatiInQuadro, nel task TIpo Incarico quando viene selezionata una specilizzazione viene popolata la tabella degli incaricati in quadro.
 * @function fetchListaIncarichiInQuadro
 * @param {String} specializzazzione Tipo specilizzazione
 * @returns getListaIncaricatiInQuadro popola tabella Incaricati in quadro
*/
export const fetchListaIncarichiInQuadro = (specializzazzione) => async (dispatch) => {
  if (specializzazzione) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/incarichi/incaricati/${specializzazzione}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getListaIncaricatiInQuadro(res.data));
  }
  return null;
};


export const fetchSlaCheck = (tipoIncarico, specializzazione) => async (dispatch) => {
  if(tipoIncarico && specializzazione) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/sla/check?tipologia=${tipoIncarico}&specializzazione=${specializzazione}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getSlaCheck(res.data));

  }
  return null;
}

/**
 * Get Lista Incarichi No Quadro, tramite il metodo getListaIncaricatoNoQuadro viene popolato il nodo listaIncaricatoNoQuadro, nel task Tipo Incarico quando viene selezionata il radio Button fiduciario fuori accordo quadro, viene popolata la tabella degli incaricati No quadro.
 * @function fetchListaIncaricatoNoQuadro
 * @param {String} incaricatoId ID Incaricato
 * @returns getListaIncaricatoNoQuadro popola tabella Incaricati No quadro
*/
export const fetchListaIncaricatoNoQuadro = (incaricatoId) => async (dispatch) => {
  if (incaricatoId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/incarichi/incaricato/${incaricatoId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getListaIncaricatoNoQuadro(res.data));
  }
  return null;
};

/**
 * Get Riserva Spesa Incaricato, tramite il metodo getRiservaSpeseIncarico viene popolato il nodo riservaSpeseIncarico, si visualizza la riserva riserva all'incaricato scelto nel gtask incarichi
 * @function fetchRiserveSpeseIncarico
 * @param {String} riservaSpesaId ID Riserva Spesa
 * @returns getRiservaSpeseIncarico popola component Riserva Incarico
*/
export const fetchRiserveSpeseIncarico = (riservaSpesaId) => async (dispatch) => {
  if (riservaSpesaId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/incarichi/riserva-spesa/${riservaSpesaId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getRiservaSpeseIncarico(res.data));
  }
  return null;
};

/**
 * Get Lista Validazioni, tramite il metodo getValidazioniIstruttoria viene popolato il nodo validazioniIstruttoria, popola tabella validazioni in istruttoria
 * fetchValidazioniIstruttoria
 * @param {String} idSinistro ID Sinistro
 * @returns getValidazioniIstruttoria popola tabella validazioni in istruttoria
*/
export const fetchValidazioniIstruttoria = (idSinistro) => async (dispatch) => {
  if(idSinistro){
    const API_URI_CALL = `${API_URL}/api/v1/bff/validazioni/lista/${idSinistro}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);

    return dispatch(getValidazioniIstruttoria(res.data));
  }
  return null;
};

/**
 * Trova nuova polizza, tramite il metodo getListaSearchPolizza viene popolato il nodo listaSearchPolizza, popola tabella con la lista delle possibili polizze.
 * @function fetchSearchPolizza
 * @param {String} polizza Numero polizza
 * @returns getListaSearchPolizza trova polizza
*/
export const fetchSearchPolizza = (polizza) => async (dispatch) => {
  dispatch(getListaSearchPolizzaCollettiva([]));
  const API_URI_CALL = `${API_URL}/api/v1/bff/overx/polizze/generica/find/${polizza}?limit=10`;
  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('err',err);
  if(res.data){
    return dispatch(getListaSearchPolizza(res.data));
  }
  return 0;
};

/**
 * Get Search Polizza Collettiva, tramite il metodo getListaSearchPolizzaCollettiva viene popolato il nodo listaSearchPolizzaCollettiva
 * @function fetchSearchPolizzaCollettiva
 * @param {String} polizza Numero Polizza
 * @returns 
*/
export const fetchSearchPolizzaCollettiva = (polizza) => async (dispatch) => {
  dispatch(getListaSearchPolizza([]));
  const API_URI_CALL = `${API_URL}/api/v1/bff/beneCollettivo/numeroPolizza/${polizza}`;
  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('err',err);
  if(res.data){
    return dispatch(getListaSearchPolizzaCollettiva(res.data));
  }
  return 0;
};


/**
 * Get Search Polizza Collettiva, tramite il metodo fetchSearchDatiBeniCollettivi viene popolato il nodo datiAmministrativi per le collettive
 * @function fetchSearchDatiBeniCollettivi
 * @param {String} polizza Numero Polizza
 * @returns 
*/
export const fetchSearchDatiBeniCollettivi = (polizza) => async (dispatch) => {
  dispatch(getListaSearchPolizzaCollettiva([]));
  const API_URI_CALL = `${API_URL}/api/v1/bff/beneCollettivo/numeroPolizza/${polizza}`;
  const fetchData = async () => axiosInstance.get(API_URI_CALL);

  const [res, err] = await executor.executeAsync(fetchData);

  console.log('err',err);
  if(res.data){
    return dispatch(getListaBeniCollettiviAmministrativi(res.data));
  }
  return 0;
};

/**
 * Chiamata per descrizione bene colletivo, tramite il metodo getListaSearchCollettiva viene popolato il nodo listaSearchCollettiva
 * @function fetchSearchCollettiva
 * @param {String} descrizioneBene 
 * @returns getListaSearchCollettiva
*/
export const fetchSearchCollettiva = (descrizioneBene) => async (dispatch) => {
  if(descrizioneBene) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/beneCollettivo/descrizioneOggetto/${descrizioneBene}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.data){
      return dispatch(getListaSearchCollettiva(res.data));
    }
  }
  return 0;
};

/**
 * Get Lista Pagamenti, tramite il metodo getListaPagamentiIstruttoria viene popolato il nodo listaPagamenti, popola tabella Pagamenti Istruttoria
 * @function fetchListaPagamenti
 * @param {String} sinistroId ID Sinstro
 * @returns getListaPagamentiIstruttoria popola tabella Pagamenti Istruttoria
*/
export const fetchListaPagamenti = (sinistroId) => async (dispatch) => {
  if(sinistroId){
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/lista/${sinistroId}/?limit=100`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.data){
      return dispatch(getListaPagamentiIstruttoria(res.data));
    }
    return 0;
  }
  return null;
};

/**
 * Get Lista Garanzie pagamenti Indennizzo, tramite il metodo getGaranziePagamentoIndennizzo viene popolato il nodo garanziePagamentoIndennizzo, dopo aver selezionato un danneggiato nella quitenza viene popolata la tabella delle garanzie per Danneggiato.
 * @function fetchListaGaranziePagamentoIndennizzo
 * @param {String} danneggiatoId ID Danneggiato
 * @param {String} pagamentoId IDPagamento
 * @returns getGaranziePagamentoIndennizzo lista tabella delle garanzie per Danneggiato
*/
export const fetchListaGaranziePagamentoIndennizzo = (danneggiatoId, pagamentoId) => async (dispatch) => {
  if(danneggiatoId && pagamentoId){
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/garanzie/${danneggiatoId}/${pagamentoId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.data){
      return dispatch(getGaranziePagamentoIndennizzo(res.data));
    }
    return 0;
  }
  return null;
};

//{{baseUrlOsBff}}/denunce/lista/danneggiatiJmil/{{nDenuncia}}/{{codiceProdotto}}
/**
 * Lista Danneggiati JMil, tramite il metodo getListaDanneggiatiJmil viene mìpopolato il nodo listaDanneggiatiJmil
 * @function fetchDanneggiatiJmil
 * @param {String} nDenuncia Numero Denuncia
 * @param {String} codiceProdotto Codice Prodotto
 * @param {String} codiceLiquidatore Codice Liquidatore
 * @param {Boolean} isOverx Se è polizza OverX
 * @returns getListaDanneggiatiJmil
 */
export const fetchDanneggiatiJmil = (nDenuncia, codiceProdotto,codiceLiquidatore, isOverx,codIspettoratoJmil) => async (dispatch) => {
  if(nDenuncia && codiceProdotto && codiceLiquidatore && codIspettoratoJmil) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/lista/danneggiatiJmil/${nDenuncia}/${codiceProdotto}/${codiceLiquidatore}/${isOverx}/${codIspettoratoJmil}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.data){
      return dispatch(getListaDanneggiatiJmil(res.data));
    }
    return 0;
  }
  return null;
};

/**
  Get Lista Beneficiari Indennizzo, tramite il metodo getListaBeneficiariInennizzo viene popolato il nodo listaBeneficiariIndennizzo, tabella dei fiduciari in Pagamenti Indennizzo
 * @function fetchListaBeneficiariIndennizzo
 * @param {String} pagamentoId ID Pagamento
 * @returns getListaBeneficiariInennizzo tabella dei fiduciari
*/
export const fetchListaBeneficiariIndennizzo = (pagamentoId) => async (dispatch) => {// todo manca chiamata
  if(pagamentoId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/lista/beneficiario-indennizzo/${pagamentoId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.data){
      return dispatch(getListaBeneficiariInennizzo(res.data));
    }
    return 0;
  }
  return null;
};

/**
 * Get Informazioni Utente, tramite il metodo getDatiUtente viene popolato il nodo userData
 * @function fetchUserData
 * @param {String} userId ID User
 * @returns getDatiUtente Info Utente
 */
export const fetchUserData = (userId) => async (dispatch) => {// todo manca chiamata
  if(userId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/utenti/${userId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.data){
      localStorage.setItem("userData", JSON.stringify(res.data)); //this is shit
      return dispatch(getDatiUtente(res.data));
    }
    return 0;
  }
  return null;
};

/**
 * Cambia stato quando viene effettuato un esito di apertura sinistro o chusura sinistro
 * @function fetchSinistroStatus
 * @param {String} nDenuncia numero Denuncia
 * @param {String} value Sinistro Aperto/Chiuso 
*/
export const fetchSinistroStatus = (nDenuncia, value) => async () => {
  if(nDenuncia && value) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/denunce/sinistro/stato/${nDenuncia}?stato=${value}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * in base al nomeEsitazione.toLowerCase() === "pagamento annullato" || nomeEsitazione.toLowerCase() === "quietanza non accettata" viene fatta una chiamata per settare lo staoo del pagamento OK o KO
 * @function fetchPagamentoStatus
 * @param {String} pagamentoId ID Pagamento
 * @param {String} value OK o KO
 * @param {String} tipoPagamento Tipo pagamento
*/
export const fetchPagamentoStatus = (pagamentoId,value,tipoPagamento) => async () => {
  if(pagamentoId && value) {  
    let API_URI_CALL="";
    if(tipoPagamento==="PAGAMENTO_SPESA")
      API_URI_CALL=`${API_URL}/api/v1/bff/pagamenti/pagamentoSpesa/statoPagamento/${pagamentoId}`;
    else
      API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/pagamentoIndennizzo/statoPagamento/${pagamentoId}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,{statoPagamento:value});

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * quando viene effettuato un esito di fiduciario nominato settiamo sull'incaricato lo stato di nominato
 * @function fetchIncaricatoStatus
 * @param {String} id ID incaricato
 * @param {String} value CHIUSO/NOMINATO/RIATTIVATO 
*/
export const fetchIncaricatoStatus = (id,value, dataRestituzione, motivazione,deroga) => async () => {
  if(id) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/incarichi/stato/${id}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,{statoIncarico:value, dateRestituzione:dataRestituzione,  motivazioneSla:motivazione !== '' ? motivazione : null, deroga});

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * quando viene effettuato un esito di fiduciario nominato settiamo sull'incaricato lo stato di nominato
 * @function checkStatoIncarichi
 * @param {String} incaricoId ID incaricato
 * @param {String} value CHIUSO/NOMINATO/RIATTIVATO 
 * @param {date} value dataRestituzione
 * @param {string} value motivazioneSla
*/
export const checkStatoIncarichi = (incaricoId, value, dataRestituzione, motivazione, deroga) => async (dispatch) => {
  if(incaricoId) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/incarichi/stato/check/${incaricoId}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,{statoIncarico:value, dateRestituzione:dataRestituzione, motivazioneSla:motivazione !== '' ? motivazione : null, deroga});

    const [res, err] = await executor.executeAsync(fetchData);
    if(res) {
      dispatch(setSlaAndStato({ sla: res.data.sla, statoSla: res.data.statoSla }));
    }
    console.log('res',res);
    console.log('err',err);
    
    // Return response if successful, otherwise return error
    return err || res;
  }
  return null; // Return null if no incaricoId provided
};

/**
 * quando viene effettuato un esito su un task di causa con il valore dello stato aperta o chiusa
 * @function fetchCausaStatus
 * @param {String} id ID incaricato
 * @param {String} value CHIUSA/APERTA 
 */
export const fetchCausaStatus = (id,value) => async () => {
  if(id) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/cause/stato/${id}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,{stato:value});

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * Cambia stato quando viene effettuato un esito di apertura sinistro o chusura sinistro
 * @function fetchRecuperostatus
 * @param {String} recuperoId numero recuperoId
 * @param {String} value Sinistro Aperto/Chiuso 
*/
export const fetchRecuperoStatus = (recuperoId, value) => async () => {
  if(recuperoId && value) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/recupero/stato/${recuperoId}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL, {stato: value});

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * quando viene effettuato un esito su un task di causa con il valore dello stato aperta o chiusa
 * @function fetchDanneggiatoStatus
 * @param {String} id ID incaricato
*/
export const fetchDanneggiatoStatus = (id,value,idSinistro) => async (dispatch) => {
  if(id) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/denunce/danneggiato/chiudi/${id}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,{statoDanneggiato:value});

    const [res, err] = await executor.executeAsync(fetchData);
    if(res){
      dispatch(fetchListaDanneggiati(idSinistro))
    }
    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * quando viene effettuato un esito su un task di causa con il valore dello stato aperta o chiusa
 * @function fetchRiservaNettaLorda
 * @param {String} id TaskId
 * @param {String} value valore LORDO/NETTO
*/
export const fetchRiservaNettaLorda = (id,value) => async (dispatch) => {
  if(id && value) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/denunce/update/gestione/${id}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,{gestioneSinistro:value});

    const [res, err] = await executor.executeAsync(fetchData);
    if(res){
      dispatch(setRiservaNettaLorda(value))
    }
    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};
/**
 * quando viene effettuato un esito su un task di causa con il valore dello stato aperta o chiusa
 * @function fetchRiservaNettaLorda
 * @param {String} id TaskId
 * @param {String} value valore LORDO/NETTO
*/
export const fetchRecuperoFranchigiaIstruttoria = (sinistroId) => async (dispatch) => {
  if(sinistroId) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/recupero/lista/${sinistroId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);
    if(res){
      dispatch(setRecuperoFranchigiaIstruttoria(res.data))
    }
    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * Get Informazioni Utente, tramite il metodo getDatiUtente viene popolato il nodo userData
 * @function fetchRicercaDuplicato
 * @param {String} text testo da cercare
 * @param {String} type tipo:id/polizza/sinistro
 * @returns setListDuplicati lista elementi della ricerca
 */
 export const fetchRicercaDuplicato = (text,type) => async (dispatch) => {// todo manca chiamata
  let API_URI_CALL;
  if(text.trim().length>0) {
    switch(type){
      case "id":
        API_URI_CALL = `${API_URL}/api/v1/bff/denunce/cercaSinistriAndDenunce/idTask/${text}`;
        break; 
      case "polizza":
        API_URI_CALL = `${API_URL}/api/v1/bff/denunce/cercaSinistriAndDenunce/numeroPolizza/${text}`;
        break; 
      case "sinistro":
        API_URI_CALL = `${API_URL}/api/v1/bff/denunce/cercaSinistri/sinistroId?idPerSinistro=${text}`;
        break;
      case "contraente":
        API_URI_CALL = `${API_URL}/api/v1/bff/denunce/cercaSinistro/contraente/${text}`;
        break;
      case "codiceDelegataria":
        API_URI_CALL = `${API_URL}/api/v1/bff/denunce/cercaSinistriAndDenunce/codiceDelegataria/${text}`;
        break;
      case "numeroSinistroExternal":
        API_URI_CALL = `${API_URL}/api/v1/bff/denunce/cercaSinistriAndDenunce/numeroSinistroExternal/${text}`;
        break;
      default:
          break;
    }
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.data && res.data.length>0){
      return dispatch(setListDuplicati(res.data));
    }
    if(res.data){
      return dispatch(setListDuplicati([res.data]));
    }
    return dispatch(setListDuplicati([]));
    // return dispatch(setListDuplicati([
    //   { id: "10", numeroPolizza: "20", numeroSinistro: "30", esito: "test" },
    //   { id: "100", numeroPolizza: "200", numeroSinistro: "300", esito: "test2" }
    // ]));
 
  }
  return dispatch(setListDuplicati([]));
};

/**
 * Get Informazioni Utente, tramite il metodo getDatiUtente viene popolato il nodo userData
 * @function fetchDuplicatoSalva
 * @param {String} id ID del task
 * @returns getDatiUtente Info Utente
 */
 export const fetchDuplicatoSalva = (duplicatoId,emailId,paramsFetchPostEsita,taskId,taskIdInCharge) => async (dispatch) => {// todo manca chiamata
  if(duplicatoId) {
    const payload={
      "idEmail": emailId,
      "codicePraticaDaAssociare": duplicatoId,
      "taskIdAttuale": taskId,
      "esitaTask":paramsFetchPostEsita
    }
    const API_URI_CALL = `${API_URL}/api/v1/bff/email/riconciliazioneTask`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,payload);
    dispatch(toggleIsSlowFormVisible(true));
    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err',err);
    if(res.status === 200){
      //--> gestione toast
      const uuID = crypto.randomUUID();  //simuliamo una requestId
      const itemMessageToast = {
        "requestId": uuID,
        "entityName": "Esitazione task",
        "taskId": duplicatoId,
        "fetchToEval": "Esitazione",
        "idTaskPadre":"",
        "navigate":true,
        "navigate2father": false,
        "loader":""
      } //genera l'eval di un fetch

      dispatch(setTotalItemMessageToast(itemMessageToast));
      //Simuliamo la risposta
      const itemOk = `{"requestId":"${uuID}", "operation":{"status":"SUCCESS", "statusCode": "7200"}}`
      const itemOkObj = { "data": itemOk }
      //simuliamo il dispatch della ws
      dispatch(checkTotalItemMessageToast(itemOkObj));

      // console.log('Res on Esiti ', response.data);
      // --> gestione NavTab
      dispatch(removeTaskToNav({ taskId }));
      dispatch(removeIdTicketInCharge(taskId)); //free frontend
      if (taskIdInCharge === `${taskId};`) {
        //se esistono altri ticket in modalità di modifica occorre lasciare invariato lo stato del customizer
        //diversamente andiamo in toggle
        dispatch(EnabledDisabledStateTrueFalsePresaCarico());
      }
      dispatch(toggleIsSlowFormVisible(false));
      dispatch(removeOpenedTicket({ taskId })); //TicketReducer
    }
    return 0;
 
  }
  return null;
};

/**
 * Get per calcolo massimali denunce/garanzia/cumoli?dataEvento=2023-06-01T08:49:04.000Z&dataScadenza=2023-07-30T20:49:04.000Z&dataEmissione=2023-05-03T12:49:04.000Z&garanziaPrincipale=RCT - Responsabilità Civile verso Terzi&keyMassimale=massimalePersone&valoreMassimale=200000
 * @function fetchGetMassimali
 * @param {String} nDenuncia numero Denuncia
 * @param {String} value Sinistro Aperto/Chiuso 
*/
export const fetchGetMassimali = (dataEvento, dataScadenza, dataEmissione, garanziaPrincipale, keyMassimale, valoreMassimale, numeroPolizza) => async (dispatch) => {
  
  if(dataEvento && dataScadenza && dataEmissione && garanziaPrincipale && keyMassimale && valoreMassimale) {  
    const API_URI_CALL=`${API_URL}/api/v1/bff/denunce/garanzia/cumoli?dataEvento=${dataEvento}&dataScadenza=${dataScadenza}&dataEmissione=${dataEmissione}&garanziaPrincipale=${garanziaPrincipale}&keyMassimale=${keyMassimale}&valoreMassimale=${valoreMassimale}&numeroPolizza=${numeroPolizza}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);
    
    console.log('res',res);
    console.log('err',err);
    if(res.data){
      dispatch(getCalcoloMassimali(res.data));
    }
    
  }
};


/**
 * get Lista BeneficiariRecuperi, per popolare la lista nella modale dei recuperi
 * @function fetchListaBenRecuperoFranchigia
 * @param {String} idPagamento ID idPagamento
 * @returns lista beneficiari recupero franchigia
*/
export const fetchListaBenRecuperoFranchigia = (idPagamento) => async (dispatch) => {
  if (idPagamento) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/lista/beneficiario-indennizzo/${idPagamento}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(setListaBeneficiariRecuperoFranchigia(res.data));
  }
  return null;
};

/**
 * get Lista BeneficiariRecuperi, per popolare la tabella dei recuperi
 * @function fetchListaBenRecuperoFranchigia
 * @param {String} idRecuoero ID idRecuoero
 * @returns lista beneficiari table recupero franchigia
*/
export const fetchListaBenRecuperoFranchigiaTable = (idRecuoero) => async (dispatch) => {
  if (idRecuoero) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/recupero/lista/beneficiario-franchigia/${idRecuoero}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(setListaBeneficiariRecuperoFranchigiaTable(res.data));
  }
  return null;
};
/**
 * get Lista BeneficiariRecuperi, per popolare la tabella dei recuperi
 * @function fetchListaBenRecuperoFranchigia
 * @param {String} idRecuoero ID idRecuoero
 * @returns lista beneficiari table recupero franchigia
*/
export const fetchListaAllegatiPagamentoIndennizzo = (idSinistro,tipologiaAllegato) => async (dispatch) => {
  if (idSinistro) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/lista/allegati/${idSinistro}/tipologiaAllegato/${tipologiaAllegato}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);
    if(tipologiaAllegato==="Quietanza"){
      return dispatch(getListaAllegatiQuietanza(res.data));
    }
    if(tipologiaAllegato === "PagoPA"){
      return dispatch(getListaAllegatiPagoPa(res.data));
    }
    if(tipologiaAllegato === "Giustificativo"){
      return dispatch(getListaAllegatiPagamentoSpese(res.data));
    }
    return dispatch(getListaAllegatiPagamentoIndennizzo(res.data));
  }
  return null;
};
/**
 * get Lista BeneficiariRecuperi, per popolare la tabella dei recuperi
 * @function fetchListaBenRecuperoFranchigia
 * @param {String} idRecuoero ID idRecuoero
 * @returns lista beneficiari table recupero franchigia
*/
export const fetchAllegatoPagamentoIndennizzo = (pagamentoId) => async (dispatch) => {
  if (pagamentoId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/lista/allegati/pagamento/${pagamentoId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getAllegatoPagamentoIndennizzo(res.data));
  }
  return null;
};
/**
 * get Lista BeneficiariRecuperi, per popolare la tabella dei recuperi
 * @function fetchListaBenRecuperoFranchigia
 * @param {String} idRecuoero ID idRecuoero
 * @returns lista beneficiari table recupero franchigia
*/
export const fetchAllegatoPagamentoSpesa = (pagamentoId) => async (dispatch) => {
  if (pagamentoId) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/lista/allegati/pagamentoSpesa/${pagamentoId}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('err', err);

    return dispatch(getAllegatoPagamentoIndennizzo(res.data));
  }
  return null;
};

export const fetchSinistroIngente = (sinistroIngente,sinistroId) => async (dispatch) => {

  if (sinistroId) {
    const obj={
      "sinistroIngente": sinistroIngente===""?null:sinistroIngente
    }
    const API_URI_CALL = `${API_URL}/api/v1/bff/denunce/update/sinistroIngente/${sinistroId}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL,obj);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res', res);
    console.log('err', err);
    if(res){
      return dispatch(setSinistroIngente(sinistroIngente));
    }
  }
  return null;
};

export const fetchValidazioneSalvaNota = (idValidazione,nota) => async (dispatch) => {
  if (idValidazione && nota) {
    const API_URI_CALL = `${API_URL}/api/v1/bff/validazioni/update/notaResponsabile/${idValidazione}?notaResponsabile=${nota}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL);
    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res', res);
    console.log('err', err);
    if(res){
      return dispatch(setValidazioneNotaResponsabile(nota));
    }
  }
  return null;
};

/**
 * Eseguo Put per aggiornare a Db Idbene quando cambio id dalla tabella beni assicurati
 * @function fetchSinistroStatus
 * @param {String} nDenuncia numero Denuncia
 * @param {String} idBene idBene Collettivo selezionato
 * @param {String} nPolizza numero Polizza
*/
export const fetchPutIdBeneCollettive = (nDenuncia, idBene, nPolizza) => async () => {
  if(nDenuncia && idBene && nPolizza) { 
    const objPutInBene = {
      "numeroPolizza": nPolizza,
      "idBene": idBene
    } 
    const API_URI_CALL=`${API_URL}/api/v1/bff/denunce/update/idBene/${nDenuncia}`;
    const fetchData = async () => axiosInstance.put(API_URI_CALL, objPutInBene);

    const [res, err] = await executor.executeAsync(fetchData);

    console.log('res',res);
    console.log('err',err);
  }
  return 0;
};

/**
 * Salva dati shellClaim 
 * @param {*String} taskId 
 * @param {*String} idSinistro 
 * @param {*Object} datiShellClaim 
 * @returns 
*/
export const fetchSaveDatiShellClaim = (taskId, idSinistro, datiShellClaim) => async (dispatch) => {
  if(taskId && idSinistro && datiShellClaim){
    return axiosInstance.post(`${API_URL}/api/v1/bff/ShellClaim/create/${idSinistro}`, datiShellClaim)
    .then(() => {

    }).catch((e)=>{
      axiosInstance.Cattura(e,taskId,"Dati Shell Claim Aggiornati","fetchToEval",dispatch);
    });
  }
  return null;
}

export const fetchGetOperatori = (taskId, itemEsitazione, queueId) => async (dispatch) => {
  if (taskId && itemEsitazione > 0) {
    const API_URI_CALL_DAYS = `${API_URL}/api/v1/bff/task/operatori/${taskId}/${queueId}/${itemEsitazione}`
    const fetchDataCallDays = async () => axiosInstance.get(API_URI_CALL_DAYS);
    const [resUser, errUser] = await executor.executeAsync(fetchDataCallDays);
    console.log('errUser ', errUser);
    if(resUser){
      return dispatch(setOperatori(resUser.data));
    }
  }
  return null;
}

export const fetchGetValidazioneIban = (iban, setCollapsed, setIbanCheck, setMetodoPagamento, handleChildData, controllFormInput, setLoaderBtn, setShowAnnoMounth, typePagamento, setDisabledSepa) => async (dispatch) => {
  if (iban !== '' && iban !== " " && iban !== null && iban !== undefined) {
    setLoaderBtn(true)
    const API_URI_CALL = `${API_URL}/api/v1/bff/pagamenti/check/iban/${iban}`;
    const fetchData = async () => axiosInstance.get(API_URI_CALL);
 
    const [res, err] = await executor.executeAsync(fetchData);
 
    console.log('err', err);
   
    if (err?.status !== null && err?.status !== undefined && err?.status !== "") {
      const objErrors = {
        "validations": {
          "err": {
            "code": `${err.status}`,
            "message": "Problemi con il servizio di validazione Iban, riprovare",
            "passedValidation": true
          }
        }
      }
      dispatch(getValidazioneIban(objErrors));
      setLoaderBtn(false)
      setIbanCheck(false)
      return setCollapsed(true);
    }
    const staus = res.data.sepa_data.SCT
    const controllFormInputValid = controllFormInput
    if(staus === "YES"){
      if(typePagamento) {
        setShowAnnoMounth(false)
      }
      if((controllFormInputValid.metodoDiPagamento || controllFormInputValid.metodoPagamento) === "AD"){
        setShowAnnoMounth(true)
      }
    }else{
      setMetodoPagamento("BONIFICO_MANUALE")
      setShowAnnoMounth(false)
      if((controllFormInputValid.metodoDiPagamento || controllFormInputValid.metodoPagamento ) === "AD"){
        setShowAnnoMounth(true)
        setMetodoPagamento("AD")
      }
      if(typePagamento  === EnumService.TIPOPAGAMENTO.LQIND) {
        setDisabledSepa(true)
      }
      if(controllFormInput !== undefined && controllFormInput !== null && typePagamento === EnumService.TIPOPAGAMENTO.LQIND){
        if(handleChildData){
          handleChildData({
            ...controllFormInputValid,
            "metodoDiPagamento": "BONIFICO_MANUALE",
          })
        }
      }
    }
    setLoaderBtn(false)
    dispatch(getValidazioneIban(res.data));
    setIbanCheck(res.data.validIban)
    return setCollapsed(true);
  }
  return null;
}

export const getListaCodiceEvento = (dataEvento, regioneEvento, provinciaEvento, capevento, comuneEvento) => async (dispatch) => {
  if (dataEvento && regioneEvento && provinciaEvento && capevento && comuneEvento) {
    dispatch(setListaCodiceEvento([]));
    const date = new Date(dataEvento)
    const newDate = dateUtils.toISOStringWithTimeZone(date)
    const API_URI_CALL_DAYS = `${API_URL}/api/v1/bff/denunce/lista/eventiCatastrofali/${newDate}/${regioneEvento}/${provinciaEvento}/${comuneEvento}/${capevento}`
    const fetchGetCodiceEvento = async () => axiosInstance.get(API_URI_CALL_DAYS);
    const [resCodEv, errCodEv] = await executor.executeAsync(fetchGetCodiceEvento);
    console.log('errUser ', errCodEv);
    if(resCodEv){
      return dispatch(setListaCodiceEvento(resCodEv.data));
    }
  }
  return dispatch(setListaCodiceEvento([]));
}

export default TicketSlice.reducer;
